import React from 'react'
import Layout from '../../components/Layout'
import PropTypes from 'prop-types'

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customerName: '',
      customerEmail: '',
      message: '',
    }
    if (props.location.state) {
      this.state.customerName = props.location.state.name
      this.state.customerEmail = props.location.state.email
      this.state.message = props.location.state.message
    }
  }

  render() {
    const { customerName, customerEmail, message } = this.state
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>お問い合わせありがとうございます</h1>
              <p>以下の内容でお問い合わせを受け付けました</p>
              <p>
                通常3営業日以内に担当者よりご連絡いたします。今しばらくお待ちください。
              </p>
              <div className="field">
                <label className="label" htmlFor={'name'}>
                  お名前
                </label>
                <div className="control">{customerName}</div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'email'}>
                  メールアドレス
                </label>
                <div className="control">{customerEmail}</div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'message'}>
                  お問い合わせ内容
                </label>
                <div className="control">{message}</div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

Index.propTypes = {
  name: PropTypes.string,
}
